<template>
  <div>
    <div class="dev-grid">
      <div v-for="idx in 12" :key="idx">{{ idx }}</div>
    </div>
    <div class="dev-grid__left" />
    <div class="dev-grid__center" />
    <div class="dev-grid__right" />
  </div>
</template>

<style lang="postcss" scoped>
.dev-grid,
.dev-grid__left,
.dev-grid__right,
.dev-grid__center {
  --helpercolor: 4 90% 58%;
  z-index: 100000000;
}

.dev-grid__center {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: chartreuse;
}

.dev-grid__left {
  @media (--xl) {
    position: absolute;
    top: 0;
    left: var(--grid-gap);
    width: 1px;
    height: 100%;
    background: chartreuse;
  }
}

.dev-grid__right {
  @media (--xl) {
    position: absolute;
    top: 0;
    right: var(--grid-gap);
    width: 1px;
    height: 100%;
    background: chartreuse;
  }
}

.dev-grid {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: repeat(var(--grid-cols), cols(1));
  padding: 0 var(--grid-margin);
  gap: var(--grid-gap);
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    background: hsla(var(--helpercolor) / 0.3);
  }

  &::after {
    content: " ";
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100%;
    height: var(--header-height);
    background: hsla(var(--helpercolor) / 0.1);
  }

  div {
    position: relative;
    color: hsla(var(--helpercolor) / 1);
    font-size: 10px;

    display: flex;
    align-items: flex-start;
    padding-top: calc(var(--header-height) * 1.5);
    justify-content: center;
    width: calc(var(--col));

    background: hsla(var(--helpercolor) / 0.1);

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background: hsla(var(--helpercolor) / 1);
    }

    &::after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background: hsla(var(--helpercolor) / 1);
    }

    /*
    border-left: 1px solid hsla(var(--helpercolor) / 1);
    border-right: 1px solid hsla(var(--helpercolor) / 1);
    */

    /* &:nth-of-type(n + 7) {
      display: none;
    }

    @media (--md) {
      &:nth-of-type(n + 7) {
        display: flex;
      }
    } */
  }
}
</style>
